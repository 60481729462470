@import '/theme.css';
@import '/utilities.css';

.componentBase {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: sizeX2s sizeXs;
  line-height: 1.265625; /* Ensure the same height as form inputs */
  background-color: cBlue;
  color: cWhite;
  font-size: fontSizeXs;
  text-align: inherit;
  cursor: pointer;
  transition: color easeOutProductive durationXs, background-color easeOutProductive durationXs;
  box-shadow: boxShadowNarrow;

  &:--enter {
    background-color: cBlueGray;
  }

  &.disabled {
    background-color: cGray;
  }

  &.disabled,
  &.disabled:--enter {
    color: cGrayLight;
    cursor: default;
  }

  &.componentGhost,
  &.componentGhostBack,
  &.componentGhostForward {
    background-color: transparent;
    color: cBlue;
    border: solid 1px cBlue;
    box-shadow: boxShadowNarrow;

    & > .icon {
      fill: cBlue;
    }

    &:--enter:not(.disabled) {
      padding: calc(sizeX2s - 2px) calc( sizeXs - 2px);
      border: solid 3px cBlue;
    }

    &.disabled,
    &.disabled:--enter {
      color: cGray;
      cursor: default;
    }
  }

  &.componentNaked {
    background-color: transparent;
    color: cBlue;
    padding-left: 0;
    padding-right: 0;

    &:--enter {
      background-color: transparent;
      color: cBlueDark;
    }

    &.disabled,
    &.disabled:--enter {
      color: cGray;
      cursor: default;
    }
  }

  & > :not(:first-child) {
    margin-left: sizeX3s;
  }

  & > .icon {
    fill: cWhite;
  }
}

.iconReverse {
  transform: scale(-1, 1);
}
