.loader {
  animation: spin 600ms linear infinite;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top: 2px solid currentColor;
  display: inline-block;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
