@import '/theme.css';
@import '/utilities.css';

.componentWrapper {
  & > .componentLabel {
    margin-bottom: sizeX4s;
  }

  & > .componentDescription,
  & > .componentError {
    margin-top: sizeX4s;
  }
}

.componentLabel {
  display: block;
  cursor: pointer;
  font-size: fontSizeS;
  line-height: lineHeightSm;

  &.disabled {
    color: cGray;
    cursor: default;
  }

  &.required::after {
    content: ' *';
    color: cBlue;
  }
}

/* Wrap the input and the icon. Allows you to align things with the input. */
.field {
  position: relative;

  & > .icon {
    position: absolute;
    right: sizeX2s;
    top: 50%;
    transform: translateY(-50%);
    color: cBlue;
    pointer-events: none;
  }

  & > .icon + .input {
    padding-right: sizeXl;
  }

  & > .input,
  & > .componentSelect {
    width: 100%;
  }
}

.smallField {
  width: 120px !important;
}

/* The actual form control */
.input {
  display: block;
  padding: sizeX2s;
  box-shadow: inset 0 0 0 1px cGray;
  border: 0;
  border-radius: borderRadiusSmall;
  line-height: 1.125;
  font-size: fontSizeS;
  appearance: none;
  background: white;
  transition: box-shadow easeOutProductive durationS;

  &::placeholder {
    color: cGray;
  }

  &[disabled] {
    color: cGray;
    cursor: not-allowed;
  }

  &.fat {
    padding: sizeXs sizeX2s;
  }
}

.inputDate {
  &[readOnly] {
    cursor: pointer;
  }
}

/* Description shown below the input */
.componentDescription {
  font-size: fontSizeXs;
  color: cGray;
  line-height: 1;
}

/* Error shown below the input */
.componentError {
  font-size: fontSizeXs;
  color: cRed;
  line-height: 1;
}

/* Input variations */

.input.textarea {
  min-height: 10rem;
  resize: vertical;
}

.input.checkbox,
.input.radio {
  @apply --visuallyHidden;
}

.checkboxLabel,
.radioLabel {
  position: relative;
  display: block;
  font-size: fontSizeS;
  line-height: 1.125;
  padding-left: sizeMd;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 1.9rem;
    height: 1.9rem;
    left: 0;
    top: 0.56em;
    transform: translateY(-50%);
  }

  &::before {
    box-shadow: inset 0 0 0 1px cGray;
    transition: box-shadow easeOutProductive durationS;
    background-color: cWhite;
  }
}

:focus + .checkboxLabel::before,
:focus + .radioLabel::before {
  box-shadow: inset 0 0 0 1px cBlue;
}

[disabled] + .checkboxLabel,
[disabled] + .radioLabel {
  color: cGray;
}

/* Checkbox */

.checkboxLabel::before {
  border-radius: borderRadiusSmall;
}

:checked + .checkboxLabel::before {
  box-shadow: inset 0 0 0 2px transparent;
}

.checkboxLabel::after {
  width: 2.5rem;
  height: 2.5rem;
  transform: translate(-3px, -50%);
  transition: transform easeOutExpressive durationS, opacity easeOutProductive durationS;
  background-image: url(/icons/icon-checkbox.svg);
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
}

:checked + .checkboxLabel::after {
  opacity: 1;
}

[disabled] + .checkboxLabel::before {
  box-shadow: inset 0 0 0 1px cGray;
}

[disabled]:checked + .checkboxLabel::before {
  box-shadow: inset 0 0 0 2px transparent;
}

[disabled]:checked + .checkboxLabel::after {
  background-image: url(/icons/icon-checkbox-grey.svg);
}

/* Radio */

.radioLabel::before {
  border-radius: 50%;
}

:checked + .radioLabel::before {
  box-shadow: inset 0 0 0 2px cBlue;
}


[disabled]:checked + .radioLabel::before {
  box-shadow: inset 0 0 0 2px cGray;
}

.radioLabel::after {
  border-radius: 50%;
  background-color: cBlue;
  width: .7rem;
  height: .7rem;
  left: 0.6rem;
  transform: translateY(-50%) scale(0.5);
  opacity: 0;
  transition: transform easeOutExpressive durationS, opacity easeOutProductive durationS;
}

:checked + .radioLabel::after {
  transform: translateY(-50%);
  opacity: 1;
}

[disabled] + .radioLabel::after {
  background-color: cGray;
}

.componentSelect {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;

  & > .inputSelect {
    width: 100%;
  }

  & > .inputIcon {
    position: absolute;
    right: sizeX3s;
  }
}

.inputIcon {
  pointer-events: none;
  color: cBlue;
}

.iconButton {
  color: cBlue;
}

.inputSelect {
  appearance: none;

  &::-ms-expand {
    display: none;
  }
}

.fileUpload {
  position: relative;
  z-index: 0;

  & > .file {
    z-index: 1;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  & > .fileField {
    z-index: 0;
  }
}

.file {
  opacity: 0;
  cursor: pointer;
}

.fileField {
  position: relative;

  &.isEmpty {
    color: cGray;
  }

  & > .inputIcon,
  & > .iconButton {
    position: absolute;
    right: sizeX3s;
  }
}

.hideSpinButtons {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;
}
